import { lazy, Suspense } from 'react';
import { WaplUiProvider, Mui } from '@wapl/ui';
import ReactDOM from 'react-dom';
import { configure } from 'mobx';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './index.css';

export const queryClient = new QueryClient({
  defaultOptions: { queries: {}, mutations: {} },
});

const MobileApp = lazy(() => import('~/App'));
const DesktopApp = lazy(() => import('~d/App'));

configure({
  enforceActions: 'never',
});

const AppEntry: React.FC = () => {
  return (
    <Suspense fallback={<></>}>
      <QueryClientProvider client={queryClient}>
        <WaplUiProvider>
          <Mui.CssBaseline />
          {process.env.REACT_APP_HOST_TYPE === 'desktop' && <DesktopApp />}
          {process.env.REACT_APP_HOST_TYPE === 'mobile' && <MobileApp />}
        </WaplUiProvider>
      </QueryClientProvider>
    </Suspense>
  );
};

ReactDOM.render(<AppEntry />, document.getElementById('root'));
